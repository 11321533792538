<template>
	<!-- 预出库汇总 -->
	<div class="preDeliverySummary">
		<!-- 导出模板 -->
		<div id="export" style="position: fixed;top: -9999999px;">
			<div :id="'template-title'">
				<div style="line-height: 50px;font-size: 20px;" align=center>
					{{ searchForm.begin }}至{{ searchForm.end }}待配商品汇总
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="100%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td>
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
							<td>
								<font>配货仓库：<span>{{ warehouseName }}</span></font>
							</td>
						</tr>
						<tr>
							<td>
								<font>提货方式：<span>{{ deliveryName }}</span></font>
							</td>
							<td v-if="searchForm.delivery_type == 2">
								<font>
									配送路线：<span>{{ routeName ? routeName : '全部' }}</span>
								</font>
							</td>

						</tr>
					</tbody>
				</table>
			</div>
			<div :id="'template-table'">
				<table border=1 cellSpacing=0 cellPadding=1 width="100%"
					style="border-collapse:collapse;font-size:14px;" bordercolor="#333333">
					<thead>
						<tr>
							<td width="10%">
								<div align=center><b>序号</b></div>
							</td>
							<td width="30%">
								<div align=center><b>商品名称</b></div>
							</td>
							<td width="10%">
								<div align=center><b>自定义属性</b></div>
							</td>
							<td width="10%">
								<div align=center><b>数量</b></div>
							</td>
							<td width="10%">
								<div align=center><b>销售价</b></div>
							</td>
							<td width="10%">
								<div align=center><b>成本价</b></div>
							</td>
							<td width="20%">
								<div align=center><b>商品卖点</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<div class="export-template" v-for="(item, index) in selectionList" :key="index">
							<tr>
								<td align=center>{{ index + 1 }}</td>
								<td align=center>{{ item.goods_name }}</td>
								<td align=center>{{ item.goods_attr }}</td>
								<td align=center>{{ item.count }}</td>
								<td align=center>{{ item.price }}</td>
								<td align=center>{{ item.cost_price }}</td>
								<td align=center>{{ item.goods_intro }}</td>
							</tr>
						</div>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 打印模板 -->
		<div id="print_summary_detail" class="export-template" style="position: fixed;top: -999999px;">
			<div id="template-title">
				<div style="line-height: 50px;font-size: 22px;" align=center>
					<strong>
						<font>{{ searchForm.begin }}至{{ searchForm.end }}单品配送地址汇总</font>
					</strong>
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="100%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td>
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>
							<td>
								<font>商品名称：<span>{{ goodsItem.goods_name }}</span></font>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div id="template-table">
				<table border=1 cellSpacing=0 cellPadding=1 width="100%"
					style="border-collapse:collapse;font-size: 14px;" bordercolor="#333333">
					<thead>
						<tr>
							<td width="6%" style="padding:6px 0">
								<div align=center><b>序号</b></div>
							</td>
							<td width="9%" style="padding:6px 0">
								<div align=center><b>配送仓库</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系人</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系电话</b></div>
							</td>
							<td width="35%" style="padding:6px 0">
								<div align=center><b>地址</b></div>
							</td>
							<td width="10%" style="padding:6px 0">
								<div align=center><b>订单数量</b></div>
							</td>
							<td width="10%" style="padding:6px 0">
								<div align=center><b>待配数量</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in detailTableData" :key="index">
							<td align=center style="padding:6px 0">{{ index + 1 }}</td>
							<td align=center style="padding:6px 0">{{ item.warehouse_name }}</td>
							<td align=center style="padding:6px 0">{{ item.contacts }}</td>
							<td align=center style="padding:6px 0">{{ item.phone }}</td>
							<td align=center style="padding:6px 0">{{ item.address }}</td>
							<td align=center style="padding:6px 0">{{ item.count }}</td>
							<td align=center style="padding:6px 0">{{ item.ck_count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 打印模板 -->
		<div id="print_summary_detail_warehouse" class="export-template" style="position: fixed;top: -999999px;">
			<div id="template-title">
				<div style="line-height: 50px;font-size: 22px;" align=center>
					<strong>
						<font>{{ searchForm.begin }}至{{ searchForm.end }}单品配送仓库汇总</font>
					</strong>
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="100%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td>
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>

							<td>
								<font>商品名称：<span>{{ goodsItem.goods_name }}</span></font>
							</td>
						</tr>

					</tbody>
				</table>
			</div>
			<div id="template-table">
				<table border=1 cellSpacing=0 cellPadding=1 width="100%"
					style="border-collapse:collapse;font-size: 14px;" bordercolor="#333333">
					<thead>
						<tr>
							<td width="6%" style="padding:6px 0">
								<div align=center><b>序号</b></div>
							</td>
							<td width="9%" style="padding:6px 0">
								<div align=center><b>配送仓库</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系人</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系电话</b></div>
							</td>
							<td width="35%" style="padding:6px 0">
								<div align=center><b>地址</b></div>
							</td>
							<td width="10%" style="padding:6px 0">
								<div align=center><b>订单数量</b></div>
							</td>
							<td width="10%" style="padding:6px 0">
								<div align=center><b>待配数量</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in detailWarehouseTableData" :key="index">
							<td align=center style="padding:6px 0">
								{{ item.warehouse_sort == 0 ? index + 1 : item.warehouse_sort}}
							</td>
							<td align=center style="padding:6px 0">{{ item.warehouse_name }}</td>
							<td align=center style="padding:6px 0">{{ item.contacts }}</td>
							<td align=center style="padding:6px 0">{{ item.phone }}</td>
							<td align=center style="padding:6px 0">{{ item.address }}</td>
							<td align=center style="padding:6px 0">{{ item.count }}</td>
							<td align=center style="padding:6px 0">{{ item.ck_count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- 打印模板 -->
		<div id="print_summary_detail_delivery" class="export-template" style="position: fixed;top: -999999px;">
			<div id="template-title">
				<div style="line-height: 50px;font-size: 22px;" align=center>
					<strong>
						<font>{{ searchForm.begin }}至{{ searchForm.end }}单品配送路线汇总</font>
					</strong>
				</div>
				<table border=0 cellSpacing=0 cellPadding=0 width="100%" style="line-height: 20px;font-size: 14px;">
					<tbody>
						<tr>
							<td>
								<font>打印时间：<span>{{ currentDateTime }}</span></font>
							</td>

							<td>
								<font>商品名称：<span>{{ goodsItem.goods_name }}</span></font>
							</td>
						</tr>

					</tbody>
				</table>
			</div>
			<div id="template-table">
				<table border=1 cellSpacing=0 cellPadding=1 width="100%"
					style="border-collapse:collapse;font-size: 14px;" bordercolor="#333333">
					<thead>
						<tr>
							<td width="10%" style="padding:6px 0">
								<div align=center><b>序号</b></div>
							</td>
							<td width="30%" style="padding:6px 0">
								<div align=center><b>路线名称</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系人</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>联系电话</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>订单数量</b></div>
							</td>
							<td width="15%" style="padding:6px 0">
								<div align=center><b>待配数量</b></div>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in detailDeliveryTableData" :key="index">
							<td align=center style="padding:6px 0">
								{{ index + 1 }}
							</td>
							<td align=center style="padding:6px 0">{{ item.delivery_name }}</td>
							<td align=center style="padding:6px 0">{{ item.contacts }}</td>
							<td align=center style="padding:6px 0">{{ item.phone }}</td>
							<td align=center style="padding:6px 0">{{ item.count }}</td>
							<td align=center style="padding:6px 0">{{ item.ck_count }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配送日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配货仓库:</div>
					<div class="item-input">
						<el-select v-model="searchForm.warehouse_id" placeholder="请选择" @change="selectWarehouse">
							<el-option v-for="item in warehouseOptions" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">提货方式:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_type" placeholder="请选择提货方式" style="width: 100%;"
							@change="selectDeliveryType">
							<el-option v-for="item in deliveryTypeList" :key="item.id" :label="item.name"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">商品分类:</div>
					<div class="item-input">
						<el-select v-model="selectCate" multiple collapse-tags style="margin-left: 20px;"
							placeholder="请选择">
							<el-option v-for="item in cateList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入商品名称" />
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning" @click.stop="handleSearch">搜索</el-button>
					<el-button
						@click.stop="pageNum = 10, selectCate = [],warehouseName = '全部',searchForm = {},  searchForm.begin = dateVal[0], searchForm.end = dateVal[1], handleSearch()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">
			<div class="table-operation">
				<el-button @click.stop="handlePrint">打印</el-button>
				<el-button type="success" @click.stop="handleExport">导出</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>

				<span style="color:#F56C6C;padding-left:20px">根据配送日期实时查询配送单商品应配数量总和（确认数量+未确认数量)</span>
			</div>
			<el-table :data="tableData" style="width: 100%" border :header-cell-style="{
                background: '#f5f7fa',
                fontWeight: 'bold',
                color: '#303133'
            }" show-summary>
				<el-table-column type="index" label="序号" align="center"></el-table-column>
				<el-table-column prop="warehouse_name" label="配送仓库" align="center"></el-table-column>
				<el-table-column prop="category_title" label="商品分类" align="center"></el-table-column>
				<el-table-column prop="goods_name" label="商品名称" align="center"></el-table-column>
				<el-table-column prop="goods_attr" label="自定义属性" align="center"></el-table-column>
				<el-table-column prop="count" label="待配数量" align="center"></el-table-column>
				<el-table-column prop="price" label="销售价" align="center"></el-table-column>
				<el-table-column prop="cost_price" label="成本价" align="center"></el-table-column>
				<el-table-column prop="goods_intro" label="商品卖点" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="360">
					<template slot-scope="scope">
						<el-button size="mini" @click.stop="showAddressDetail(scope.row)">地址汇总</el-button>
						<el-button type="success" size="mini"
							@click.stop="showWarehouseDetail(scope.row)">仓库汇总</el-button>
						<el-button type="warning" size="mini"
							@click.stop="showDeliveryDetail(scope.row)">路线汇总</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<el-dialog :title="goodsItem.goods_name+'待配地址汇总'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="80%" @close="closeDetailDialog">
			<div class="summary-dialog">
				<div class="table-operation" style="text-align: right;">* 商品存在延期时会出现差额</div>
				<el-table :data="detailTableData" style="width: 100%" border :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
					<el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
					<el-table-column prop="warehouse_name" label="配送仓库" align="center"></el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center"></el-table-column>
					<el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="address" label="配送地址" align="center">
						<template slot-scope="scope">
							<span style="color:#F56C6C" v-if="scope.row.delivery_type == 2">[自提]</span>
							{{ scope.row.address }}
						</template>
					</el-table-column>
					<el-table-column prop="price" label="订单价" align="center"></el-table-column>
					<el-table-column prop="count" label="订单数量" sortable align="center"></el-table-column>
					<el-table-column label="待配数量" sortable align="center">
						<template slot-scope="scope">
							<span style="color:#409EFF;font-weight: 600;">{{ scope.row.ck_count }}</span>
						</template>
					</el-table-column>
					<el-table-column label="差额" align="center">
						<template slot-scope="scope">
							<span style="font-weight: 600;color:#F56C6C"
								v-if="scope.row.count - scope.row.ck_count > 0">{{ scope.row.count - scope.row.ck_count }}</span>
							<span v-else>{{ scope.row.count - scope.row.ck_count }}</span>
						</template>
					</el-table-column>
					<!-- <el-table-column prop="ck_amount" label="配送金额" align="center"></el-table-column> -->
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click.stop="handlePrintDetail" v-loading.fullscreen.lock="loading">打
					印</el-button>
				<el-button @click.stop="handleExportDetail" v-loading.fullscreen.lock="loading">导 出</el-button>
				<el-button type="info" @click.stop="closeDetailDialog">关 闭</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="goodsItem.goods_name+'待配仓库汇总'" :visible.sync="dialogWarehouseVisible"
			:close-on-click-modal="false" v-el-drag-dialog width="80%" @close="closeDetailWarehouseDialog">
			<div class="summary-dialog">
				<div class="table-operation" style="text-align: right;">* 商品存在延期时会出现差额</div>
				<el-table :data="detailWarehouseTableData" style="width: 100%" border :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
					<el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
					<el-table-column prop="warehouse_name" label="配送仓库" align="center"></el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center"></el-table-column>
					<el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="address" label="地址" align="center"></el-table-column>
					<el-table-column prop="count" label="订单数量" sortable align="center"></el-table-column>
					<el-table-column label="待配数量" sortable align="center">
						<template slot-scope="scope">
							<span style="color:#409EFF;font-weight: 600;">{{ scope.row.ck_count }}</span>
						</template>
					</el-table-column>
					<el-table-column label="差额" align="center">
						<template slot-scope="scope">
							<span style="font-weight: 600;color:#F56C6C"
								v-if="scope.row.count - scope.row.ck_count > 0">{{ scope.row.count - scope.row.ck_count }}</span>
							<span v-else>{{ scope.row.count - scope.row.ck_count }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click.stop="handlePrintDetailWarehouse" v-loading.fullscreen.lock="loading">打
					印</el-button>
				<el-button @click.stop="handleExportDetailWarehouse" v-loading.fullscreen.lock="loading">导 出</el-button>
				<el-button type="info" @click.stop="closeDetailWarehouseDialog">关 闭</el-button>
			</span>
		</el-dialog>
		<el-dialog :title="goodsItem.goods_name+'待配路线汇总'" :visible.sync="dialogDeliveryVisible"
			:close-on-click-modal="false" v-el-drag-dialog width="80%" @close="closeDetailDeliveryDialog">
			<div class="summary-dialog">
				<div class="table-operation" style="text-align: right;">* 商品存在延期时会出现差额</div>
				<el-table :data="detailDeliveryTableData" style="width: 100%" border :header-cell-style="{
					background: '#f5f7fa',
					fontWeight: 'bold',
					color: '#303133'
				}" v-loading="loading">
					<el-table-column type="index" label="序号" align="center" width="60"></el-table-column>
					<el-table-column prop="delivery_name" label="路线名称" align="center"></el-table-column>
					<el-table-column prop="contacts" label="联系人" align="center"></el-table-column>
					<el-table-column prop="phone" label="联系电话" align="center"></el-table-column>
					<el-table-column prop="count" label="订单数量" sortable align="center"></el-table-column>
					<el-table-column label="待配数量" sortable align="center">
						<template slot-scope="scope">
							<span style="color:#409EFF;font-weight: 600;">{{ scope.row.ck_count }}</span>
						</template>
					</el-table-column>
					<el-table-column label="差额" align="center">
						<template slot-scope="scope">
							<span style="font-weight: 600;color:#F56C6C"
								v-if="scope.row.count - scope.row.ck_count > 0">{{ scope.row.count - scope.row.ck_count }}</span>
							<span v-else>{{ scope.row.count - scope.row.ck_count }}</span>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<span slot="footer" class="dialog-footer">

				<el-button type="success" @click.stop="handlePrintDetailDelivery" v-loading.fullscreen.lock="loading">打
					印</el-button>
				<el-button @click.stop="handleExportDetailDelivery" v-loading.fullscreen.lock="loading">导 出</el-button>
				<el-button type="info" @click.stop="closeDetailDeliveryDialog">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import {
		getLodop
	} from '@/utils/CLodopfuncs.js'
	export default {
		data() {
			return {
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
					cate_ids: ""
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				selectionList: [],
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				routeList: [], //配送路线
				routeName: '',
				delayDialogVisible: false,
				delayForm: {},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					},
				},
				//地址明细
				dialogVisible: false,
				detailTableData: [],
				goodsItem: {},
				// 仓库
				warehouseOptions: [],
				warehouseName: "全部",
				deliveryName: "全部",
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				dialogWarehouseVisible: false,
				detailWarehouseTableData: [],
				dialogDeliveryVisible: false,
				detailDeliveryTableData: [],
				currentDateTime: this.getCurrentDateTime(),
				cateList: [],
				selectCate: []
			}
		},
		mounted() {
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			// 列表
			this.getList()
			this.getRouteList()
			this.getWarehouseList()
			this.checkNoDelivery()
			this.getCateList()
		},
		methods: {
			handleSearch() {
				this.page = 1
				this.searchForm.begin = this.dateVal[0]
				this.searchForm.end = this.dateVal[1]
				if (this.selectCate) {
					this.searchForm.cate_ids = this.selectCate.join(",")
				}
				this.getList()
			},
			getCateList() {
				this.$http.post(`erp/v1/goods_category/all`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.cateList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			closeDetailDeliveryDialog() {
				this.dialogDeliveryVisible = false
				this.detailDeliveryTableData = []
				this.goodsItem = {}
			},
			handleExportDetailDelivery() {
				this.loading = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_delivery`, {
					goods_id: this.goodsItem.goods_id,
					export: 1,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url)
					} else {
						this.$message.error(msg);
					}
				});
			},
			handlePrintDetailDelivery() {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById(
							"print_summary_detail_delivery")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			showDeliveryDetail(item) {
				this.goodsItem = item
				this.dialogDeliveryVisible = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_delivery`, {
					goods_id: item.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.detailDeliveryTableData = data
					} else {
						this.$message.error(msg);
					}
				});

			},
			showAddressDetail(item) {
				this.goodsItem = item
				this.dialogVisible = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_address`, {
					goods_id: item.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.detailTableData = data
					} else {
						this.$message.error(msg);
					}
				});

			},

			handlePrintDetail() {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById(
							"print_summary_detail")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			handleExportDetail() {
				this.loading = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_address`, {
					export: 1,
					goods_id: this.goodsItem.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url)
					} else {
						this.$message.error(msg);
					}
				});
			},

			closeDetailDialog() {
				this.dialogVisible = false
				this.detailTableData = []
				this.goodsItem = {}
			},
			showWarehouseDetail(item) {
				this.goodsItem = item
				this.dialogWarehouseVisible = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_warehouse`, {
					goods_id: item.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.detailWarehouseTableData = data
					} else {
						this.$message.error(msg);
					}
				});

			},
			handlePrintDetailWarehouse() {
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .box-l{display: flex;justify-content: space-between} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById(
							"print_summary_detail_warehouse")
						.innerHTML);
					LODOP.PREVIEW()
					LODOP.NewPageA();
				}, 500);
			},
			handleExportDetailWarehouse() {
				this.loading = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary_warehouse`, {
					export: 1,
					goods_id: this.goodsItem.goods_id,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url)
					} else {
						this.$message.error(msg);
					}
				});
			},
			closeDetailWarehouseDialog() {
				this.dialogWarehouseVisible = false
				this.detailWarehouseTableData = []
				this.goodsItem = {}
			},
			selectWarehouse(e) {
				console.log(e)
				this.warehouseOptions.forEach(i => {
					this.warehouseName = "全部"
					if (i.id == e) {
						this.warehouseName = i.name
					}
				})
			},
			selectDeliveryType(e) {
				this.deliveryTypeList.forEach(i => {
					this.deliveryName = "全部"
					if (i.id == e) {
						this.deliveryName = i.name
					}
				})
			},
			checkNoDelivery() {
				this.$http.post(`erp/v1/preck/check_no_delivery`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200 && data > 0) {

						this.$alert('今日有' + data + '件商品未生成配送单，请重新生成', '警告', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});
					}
				});
			},
			getList() {
				this.loading = true
				this.$http.post(`erp/v1/purchase/pre_ck_summary`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data
						this.selectionList = data
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 延期
			handleDelay(item) {
				this.delayDialogVisible = true
				this.delayForm.pre_ckd_mx_id = item.id
			},
			// 延期确认
			delayConfirmFn() {
				this.$http.post(`erp/v1/preck/goods_delay`, {
					...this.delayForm
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.delayDialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 打印
			handlePrint(item) {
				if (item.goods_id) {
					this.selectionList = []
					this.selectionList.push(item)
				} else {
					if (this.selectionList.length <= 0) {
						this.$message.error('请选择打印数据');
						return
					}
				}
				setTimeout(() => {
					var LODOP = getLodop();
					var titleStyle =
						"<style> .title-box{display: flex;justify-content: space-between;margin-bottom:10px} .title-box .l-item {line-height: 22px;} .title-box .l-item span {padding-right: 30px;} .title-box .bold{font-weight: bold;} .box-r img {width: 100px;height: 100px;}</style>"
					LODOP.ADD_PRINT_HTM(2, "5%", "90%", 1000, titleStyle + document.getElementById("export")
						.innerHTML);
					LODOP.PREVIEW()
					this.selectionList = []
					this.getList()
				}, 500);
			},
			// 导出
			handleExport() {
				this.$http.post(`erp/v1/purchase/pre_ck_summary`, {
					page: this.page,
					pageNum: this.pageNum,
					...this.searchForm,
					export: 1
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						window.open(data.url, '_self')
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouseList() {
				this.$http.post(`erp/v1/warehouse/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.warehouseOptions = data
					} else {
						this.$message.error(msg);
					}
				});
			},
		},
	}
</script>

<style lang="scss" scoped></style>